import React from "react";
import { NewTicketForm, LinkedOrg, LinkedOrder } from "./NewTicketForm";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  onSave: () => void;

  initValues: any;
  ticketType: "bug-report" | "technical-support";
  organization?: LinkedOrg;
  order?: LinkedOrder;
  hideComponent?: boolean;
  hidePrioritization?: boolean;
}

const style = {
  modalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

export const NewTicketModal = (props: Props) => {
  const { title, onClose, onSave, open, ...restProps } = props;

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        <NewTicketForm
          {...restProps}
          onClose={onClose}
          onSave={onSave}
          buttonsStyle={style.modalFooter}
        />
      </ModalBody>
    </Modal>
  );
};
