import React from "react";
import { Button as BSButton, ButtonProps } from "reactstrap";

type Props = ButtonProps & {
  primary?: boolean;
  danger?: boolean;
};

export default function Button(props: Props) {
  const { primary, danger, ...rest } = props;

  return (
    <BSButton
      {...rest}
      color={danger ? "danger" : "primary"}
      outline={!primary}
    />
  );
}
