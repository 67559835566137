import React, { useEffect } from "react";
import { useField } from "formik";
import * as Yup from "yup";

import TextField from "./TextField";
import SelectField from "./SelectField";
import { Alert, Input } from "reactstrap";
import Form from "./Form";
import Button from "./Button";
import SubmitButton from "./SubmitButton";
import { UploadButton, useUploader } from "./UploadButton";

export interface LinkedOrg {
  uid: string;
  name: string;
}

export interface LinkedCustomer {
  uid: string;
  user: { firstName: string; lastName: string; emailAddress?: string };
}

export interface LinkedOrder {
  id: string;
}

const TicketPayloadSchema = Yup.object<TicketPayload>({
  subject: Yup.string().required("Subject is a required field"),
  ticketType: Yup.string(),
  organizationId: Yup.string(),
  organizationName: Yup.string(),
  customerId: Yup.string(),
  customerName: Yup.string(),
  customerEmail: Yup.string(),
  orderId: Yup.string(),
  type: Yup.string(),
  component: Yup.string(),
  frequency: Yup.string(),
  severity: Yup.string(),
  steps: Yup.string(),
  expected: Yup.string(),
  actual: Yup.string(),
  details: Yup.string(),
  origin: Yup.string(),
  attachments: Yup.array().of(Yup.object()),
});

interface Props {
  initValues: any;
  ticketType: "bug-report" | "technical-support";
  organization?: LinkedOrg;
  customer?: LinkedCustomer;
  order?: LinkedOrder;
  hideComponent?: boolean;
  hidePrioritization?: boolean;
  onClose?: () => void;
  onSave?: () => void;
  buttonsStyle?: React.CSSProperties;
}

const stepsHelp = (
  <>
    <p>
      Many issues depend on arriving at a certain state, so knowing the steps to
      reproduce is crucial.
    </p>
    <p>
      These include steps such as logging in, placing an order, adding a
      specific type of item to the cart.
    </p>
    <p>Example:</p>
    <ol>
      <li>Log in to app with a user who has a phone number configured</li>
      <li>Log out of app</li>
      <li>Open profile screen</li>
      <li>Observe that phone number of last user is still visible</li>
    </ol>
  </>
);

const expectedHelp = (
  <>
    <p>
      To be a bug there must be a <em>known expected behaviour</em>, otherwise
      the request is really a suggestion / feedback.
    </p>
    <p>Examples:</p>
    <ul>
      <li>
        I expect to see the phone number I just entered as my user&apos;s phone
        number
      </li>
      <li>I expect to see the order listed as successful on the screen</li>
    </ul>
  </>
);

const actualHelp = (
  <>
    <p>
      This information should compliment the <em>expected</em> with the buggy
      behaviour you <em>actually</em> observed.
    </p>
    <p>Examples:</p>
    <ul>
      <li>The phone number listed on the screen was still empty</li>
      <li>The order was listed as &quot;unsuccessful&quot; on the screen</li>
    </ul>
  </>
);

function AttachmentsField() {
  const [field, meta, helpers] = useField("attachments");
  const { uploading, handleFile } = useUploader({
    type: "attachment",
  });
  useEffect(() => {
    helpers.setValue(uploading);
  }, [uploading]);

  return (
    <>
      {uploading.length ? (
        <>
          <strong>Attachments:</strong>
          <p>
            {uploading.map((u) => (
              <React.Fragment key={u.id}>
                <a
                  href={u.url}
                  className={
                    u.uploading
                      ? "text-warning"
                      : u.error
                      ? "text-danger"
                      : undefined
                  }
                >
                  {u.name || u.url}
                </a>
                <br />
              </React.Fragment>
            ))}
          </p>
        </>
      ) : null}

      <UploadButton label="Attach File" accept="*.*" onFile={handleFile} />
    </>
  );
}

export const NewTicketForm = (props: Props) => {
  const origin = window.location.href;

  const isBug = props.ticketType === "bug-report";
  const isSupport = !isBug;
  return (
    <Form
      path={"/api/ticket"}
      initValues={{
        ...props.initValues,
        ticketType: props.ticketType,
        organizationId: props.organization && props.organization.uid,
        organizationName: props.organization && props.organization.name,
        customerId: props.customer && props.customer.uid,
        customerName:
          props.customer &&
          `${props.customer.user.firstName} ${props.customer.user.lastName}`,
        customerEmail: props.customer && props.customer.user.emailAddress,
        orderId: props.order && props.order.id,
        origin,
      }}
      onSave={props.onSave}
      submitButton={false}
      validationSchema={TicketPayloadSchema}
      new
    >
      <Input
        id="ticketType"
        name="ticketType"
        type="hidden"
        value={props.ticketType}
      />
      {isBug ? (
        <p>
          Please fill in the fields that are relevant to your bug.{" "}
          <em>
            More information means we can verify and triage the bug faster.
          </em>{" "}
          If the bug cannot be verified it will not be accepted.
        </p>
      ) : (
        <p>
          Please fill in the fields that are relevant to your issue.{" "}
          <em>
            The more details you can provide the faster we can resolve identify
            and resolve your issue.
          </em>
        </p>
      )}

      {props.organization && (
        <p>
          This ticket is for support on{" "}
          <strong>{props.organization.name}</strong>
        </p>
      )}

      {props.customer && (
        <p>
          This ticket is for support on{" "}
          <strong>
            {props.customer.user.firstName} {props.customer.user.lastName}
          </strong>{" "}
          ({props.customer.user.emailAddress})
        </p>
      )}

      {props.order && (
        <p>
          Regarding order: <strong>{props.order.id}</strong>
        </p>
      )}
      <TextField
        name="subject"
        label="Subject *"
        placeholder="Short title for issue..."
      />
      {isSupport && (
        <SelectField
          label="Issue Type"
          name="type"
          help="This helps us get the ticket to the right person faster"
        >
          <option value="other">Other/Unknown/Unsure</option>
          <option value="app-building">App Build or Theme</option>
          <option value="configuration">Configuration</option>
          <option value="payments">Order or Payment Related</option>
          <option value="customer">Customer Issue</option>
        </SelectField>
      )}
      {isBug ? (
        <>
          <TextField
            name="steps"
            label="Steps to Reproduce"
            type="textarea"
            placeholder="Steps to reproduce, click the (i) to view more details and examples"
            help={stepsHelp}
          />
          <TextField
            name="expected"
            label="Expected Result"
            type="textarea"
            placeholder="I expect the following correct behaviour"
            help={expectedHelp}
          />
          <TextField
            name="actual"
            label="Actual Result"
            type="textarea"
            placeholder="I observed the following incorrect behaviour"
            help={actualHelp}
          />
        </>
      ) : (
        <>
          <TextField
            name="details"
            label="Details of Issue"
            type="textarea"
            placeholder="Describe your issue in detail here. Help us confirm and observe the issue so we can fix it."
            help="You can help confirm the issue by providing steps to reproduce, exact error messages, links to Papertrail, etc."
          />
        </>
      )}
      {!props.hideComponent && (
        <SelectField label="Component" name="component">
          <option value="other">None/Other/Unsure</option>
          <option value="app">App</option>
          <option value="web-ordering">Web Ordering</option>
          <option value="operator">Operator</option>
          <option value="office">Office</option>
          <option value="aston-webpos">Terminal</option>
        </SelectField>
      )}
      {!props.hidePrioritization && (
        <>
          <SelectField
            label="Frequency - how many customers affected"
            name="frequency" // likelihood/probability/
          >
            <option value="unknown">Unknown or N/A</option>
            <option value="rare">Rare - affects only a handful of users</option>
            <option value="common">Common - affects many users</option>
            <option value="widespread">
              Widespread - affects most to all users
            </option>
          </SelectField>
          <SelectField
            label="Severity - extent of impact to affected customers"
            name="severity" // impact/consequences
          >
            <option value="unknown">Unknown or N/A</option>
            <option value="trivial">
              Trivial - fully functional, eg layout or aesthetic issue
            </option>
            <option value="minor">
              Minor - core functionality unaffected, mostly usable or workaround
              available
            </option>
            <option value="major">
              Major - partially usable, core functionality affected
            </option>
            <option value="critical">
              Critical - entire component or product unable to be used
            </option>
          </SelectField>
        </>
      )}

      <Alert color="info">
        Please do not upload attachments with confidential information visible,
        such as passwords or credit card numbers. They should not be considered
        secure.
      </Alert>

      <AttachmentsField />

      <div style={props.buttonsStyle}>
        {props.onClose && (
          <Button
            type="button"
            onClick={props.onClose}
            style={{ marginRight: 6 }}
          >
            Cancel
          </Button>
        )}
        <SubmitButton label="Submit" new />
      </div>
    </Form>
  );
};
