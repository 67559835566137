import React, { ChangeEvent, ReactNode } from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { useField } from "formik";
import HelpPopover from "./HelpPopover";
import { ColSize } from "./TextField";

type Value = string | number | string[] | undefined;

interface Props {
  col?: ColSize;
  name: string;
  placeholder?: string;
  label: string;
  help?: string;
  children?: ReactNode;
  onChange?: (value: string) => void;
}

export default function SelectField<V extends Value>(props: Props) {
  const [field, meta] = useField<V>(props.name);

  const invalid = Boolean(meta.touched && meta.error);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    if (props.onChange && event.target) props.onChange(event.target.value);
  };

  return (
    <FormGroup className={props.col && `col-md-${props.col}`}>
      <Label for={field.name}>{props.label}</Label>
      <HelpPopover id={field.name + "-help"} text={props.help} />
      <Input
        {...field}
        id={field.name}
        invalid={invalid}
        type={"select"}
        placeholder={props.placeholder}
        onChange={handleChange}
      >
        {props.children ? props.children : null}
      </Input>
      <FormFeedback valid={false}>{meta.error}</FormFeedback>
    </FormGroup>
  );
}
