/* eslint-disable @typescript-eslint/ban-types */
import React, { ReactNode, useState } from "react";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import { ObjectSchema } from "yup";
import { Spinner, Alert } from "reactstrap";
import SubmitButton from "./SubmitButton";
import useSWR, { mutate } from "swr";
import Button from "./Button";

async function fetcher(input: RequestInfo, init?: RequestInit) {
  const resp = await fetch(input, init);

  if (resp.ok) {
    return resp.json();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let body: any = null;
  try {
    body = await resp.json();
  } catch (err) {
    console.error("error parsing json response:", err);
  }

  switch (resp.status) {
    case 401:
      window.location.href = "/auth/login";
      return;
    case 404:
      return null;
    default:
      throw new Error((body && body.message) || "Unknown error");
  }
}

interface FetchError {
  message: string;
  name?: string;
}

export interface FormProps<T extends object, R extends object> {
  /**
   * Path to the resource, used for GET and POST
   */
  path: string;
  /**
   * A Yup Schema or a function that returns a Yup schema
   */
  validationSchema?: ObjectSchema<T>;
  /**
   * Zero values to use when resource returns 404 or `new` is true
   */
  initValues: T;
  /**
   * Indicates the resource is new, skip the GET and use the initValues
   */
  new?: boolean;
  /**
   * Should show the submit button. Defaults to true
   * Set to false if you're providing your own, or don't need one
   */
  submitButton?: boolean;
  /**
   * onSave is called after a successful save POST
   * Useful if you want to navigate to a new resource etc.
   *
   * @param result the updated resource returned via POST
   */
  onSave?(result?: R): void;
  preSave?(data: T): boolean;
  onError?(err: unknown): void;
  method?: "POST" | "PUT";
  children: ReactNode;
}

export default function Form<T extends object, Result extends object>(
  props: FormProps<T, Result>
) {
  const {
    path,
    validationSchema,
    new: isNew = false,
    submitButton = true,
    method = "POST",
  } = props;

  const {
    data,
    mutate: revalidate,
    error,
    isValidating,
  } = useSWR<T, FetchError>(isNew ? null : path, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  const [saveError, setSaveError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  function dismissError() {
    setSaveError(null);
  }

  async function saveData(saveData: T) {
    try {
      setShowSuccess(false);
      const res = await fetch(path, {
        method,
        body: JSON.stringify(saveData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.ok) {
        setSaveError(null);
        setShowSuccess(true);
        const updated = await res.json();

        if (props.onSave) {
          props.onSave(updated);
        }

        if (isNew) {
          return;
        }

        if (updated) {
          mutate(path, updated);
        } else {
          revalidate();
        }
        return;
      }
      let errBody;
      const { message } = (errBody = await res.json());

      if (props.onError) {
        props.onError(errBody);
      }

      setSaveError(message || "Unknown error");
    } catch (err) {
      const e = err as Error;
      if (props.onError) {
        props.onError(e);
      }

      setSaveError(e.message || "Unknown error");
    }
  }

  if (isValidating && !data) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert color="danger" isOpen={true}>
        <h5 className="alert-heading">Error loading form!</h5>
        {error && error.message}
        <hr />
        <a href="#" className="alert-link" onClick={() => revalidate()}>
          Reload
        </a>
      </Alert>
    );
  }

  return (
    <Formik
      enableReinitialize
      // NOTE: this should probably be a deep merge
      // for now should be fine like this
      initialValues={{
        ...props.initValues,
        ...data,
      }}
      onSubmit={async (submittedData, helpers) => {
        if (validationSchema) {
          // Run the validation ourself, formik handles null incorrectly
          // also casts values like "1" -> 1
          try {
            submittedData = validationSchema.validateSync(submittedData);
            if (props.preSave) {
              const continueSubmit = props.preSave(submittedData);
              if (!continueSubmit) {
                return;
              }
            }
          } catch (err) {
            const e = err as Error;
            setSaveError(e.message);
            return;
          }
        }
        await saveData(submittedData);
        helpers.setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      <FormikForm>
        <Alert
          color="success"
          isOpen={showSuccess}
          toggle={() => setShowSuccess(false)}
        >
          Saved!
        </Alert>

        <Alert color="danger" isOpen={saveError !== null} toggle={dismissError}>
          {saveError}
        </Alert>

        {props.children}

        {submitButton && <FormFooter new={isNew} />}
      </FormikForm>
    </Formik>
  );
}

function FormFooter(props: { new: boolean }) {
  const { dirty } = useFormikContext();

  if (!props.new && !dirty) {
    return null;
  }

  return (
    <div
      className="position-sticky p-3 bg-light text-right"
      style={{ bottom: 0 }}
    >
      <Button type="reset">Cancel</Button> <SubmitButton new={props.new} />
    </div>
  );
}
