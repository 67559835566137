import React, { useState } from "react";
import { NewTicketModal } from "./NewTicketModal";
import { LinkedOrg, LinkedOrder, LinkedCustomer } from "./NewTicketForm";
import Button from "./Button";

interface Props {
  label: string;
  buttonClass?: string;

  title: string;
  initValues: any;
  ticketType: "bug-report" | "technical-support";
  organization?: LinkedOrg;
  customer?: LinkedCustomer;
  order?: LinkedOrder;
  hideComponent?: boolean;
  hidePrioritization?: boolean;
}

export const NewTicketButton = (props: Props) => {
  const { label, buttonClass, ...modalProps } = props;
  const [modal, setModal] = useState(false);

  const close = () => {
    setModal(false);
  };
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <Button className={buttonClass} onClick={toggle}>
        {label}
      </Button>
      <NewTicketModal
        {...modalProps}
        open={modal}
        onClose={close}
        onSave={close}
      />
    </>
  );
};
