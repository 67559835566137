import React, { useMemo, useEffect, useCallback, useReducer } from "react";

import Button from "./Button";

interface Uploader {
  /**
   * a list of files uploaded, pending and complete
   */
  uploading: Upload[];
  /**
   * Upload a file
   *
   * @param file
   */
  handleFile(file: File): Promise<void>;
}

interface Upload {
  /**
   * id of the upload
   */
  id: number;
  /**
   * URL the uploaded file can be fetched from
   */
  url?: string;
  /**
   * uploading is true while the upload is in progress
   * once it's false check the error for complete/failure
   */
  uploading: boolean;
  /**
   * the error response from an upload failure
   */
  error?: Error;
  /**
   * Display name of the upload
   */
  name?: string;
  /**
   * Format of file upload
   */
  format?: string;
}

type UploadAction =
  | { type: "GET_URL"; id: number }
  | { type: "START"; id: number; url: string; name: string; format: string }
  | { type: "FINISHED"; id: number }
  | { type: "ERROR"; id: number; error: Error };

let uploadId = 0;

const extension = (contentType: string) => {
  switch (contentType) {
    case "image/png":
      return "png";
    case "image/jpeg":
      return "jpg";
    case "image/gif":
      return "gif";
    case "application/pdf":
      return "pdf";
    case "font/ttf":
      return "ttf";
    case "font/otf":
      return "otf";
  }
};

function reduceUpload(state: Upload, action: UploadAction): Upload {
  switch (action.type) {
    case "START":
      return {
        ...state,
        url: action.url,
        name: action.name,
        format: action.format,
      };
    case "FINISHED":
      return { ...state, uploading: false };
    case "ERROR":
      return { ...state, uploading: false, error: action.error };
  }
  return state;
}

function reduceUploads(state: Upload[], action: UploadAction): Upload[] {
  switch (action.type) {
    case "GET_URL":
      return [{ id: action.id, uploading: true }, ...state];
    default:
      return state.map((s) => {
        if (s.id !== action.id) return s;
        return reduceUpload(s, action);
      });
  }
}

interface AssetParams {
  type: "asset";
  organizationUid: string;
  category: string;
  onUpload?: (file: { url: string; name: string; format: string }) => void;
  revalidate?: () => void;
}

interface AttachmentParams {
  type: "attachment";
  organizationUid?: undefined;
  category?: undefined;
  revalidate?: () => void;
}

/**
 * Create an Uploader for an organization file category
 */
export function useUploader(params: AssetParams | AttachmentParams): Uploader {
  const { organizationUid, type, category, revalidate } = params;
  const [uploading, dispatch] = useReducer(reduceUploads, []);

  const handleFile = useCallback(
    async (file: File) => {
      const id = uploadId++;

      try {
        dispatch({ type: "GET_URL", id });

        const { putUrl, getUrl, contentType, name, format } =
          await getUploadUrl({
            organizationUid,
            type,
            category,
            file,
          });

        dispatch({ type: "START", id, url: getUrl, name, format });

        await uploadFile(putUrl, contentType, file);

        dispatch({ type: "FINISHED", id });
      } catch (err) {
        dispatch({ type: "ERROR", id, error: err as Error });
      } finally {
        revalidate && revalidate();
      }
    },
    [organizationUid, type, category, revalidate]
  );

  return { uploading, handleFile };
}

async function getUploadUrl(params: {
  type: "asset" | "attachment";
  file: File;
  organizationUid?: string;
  category?: string;
}) {
  const { organizationUid, type, category, file } = params;

  if (type === "asset" && !category) {
    throw new Error("Category required for asset.");
  }

  const nameParts = file.name.split(".");
  const format = nameParts.pop() || "png";
  const name = nameParts.join(".");

  // Fetch signed S3 upload URL
  const uploadUrlResult = await fetch(
    type === "asset" ? "/api/upload/asset" : "/api/upload/attachment",
    {
      method: "POST",
      body: JSON.stringify({
        organizationUid,
        category,
        name,
        format: extension(file.type) || format,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const {
    put: putUrl,
    get: getUrl,
    contentType,
  } = await uploadUrlResult.json();

  return { putUrl, getUrl, contentType, name, format };
}

async function uploadFile(url: string, contentType: string, file: File) {
  // Perform actual upload
  const uploadResult = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": contentType,
    },
  });

  if (!uploadResult.ok) {
    throw new Error(`${uploadResult.status}: ${uploadResult.statusText}`);
  }
}

/**
 * UploadButton opens a file select dialog an emits files via onFile
 */
export function UploadButton(props: {
  uploading?: boolean;
  accept: string;
  onFile(file: File): void;
  className?: string;
  label?: string;
}) {
  const { onFile, accept } = props;

  const input = useMemo(() => {
    const input = document.createElement("input");

    input.type = "file";
    input.accept = accept;
    input.multiple = true;

    return input;
  }, [accept]);

  useEffect(() => {
    const changeHandler = (event: Event) => {
      if (!event.target) return;
      const { files } = event.target as HTMLInputElement;

      if (!files) return;

      // files isn't an array so you can't iterate it,
      // in reality you can use for..of
      // but babel/typescript complains
      for (let i = 0; i < files.length; i++) {
        const f = files[i];
        onFile(f);
      }
    };

    input.addEventListener("change", changeHandler);

    return () => input.removeEventListener("change", changeHandler);
  }, [input, onFile]);

  return (
    <Button className={props.className} onClick={() => input.click()}>
      {props.label || "Upload"}
    </Button>
  );
}
