import React from "react";
import { useFormikContext } from "formik";
import { Spinner } from "reactstrap";

import Button from "./Button";

interface Props {
  label?: string;
  new?: boolean;
  block?: boolean;
}

export default function SubmitButton(props: Props) {
  const { label = "Save", new: isNew = false, block } = props;
  const { isSubmitting, dirty } = useFormikContext();

  if (!isNew && !dirty) {
    return null;
  }

  return (
    <Button
      disabled={isSubmitting || !dirty}
      type="submit"
      primary
      block={block}
    >
      {isSubmitting && (
        <>
          <Spinner size="sm" />{" "}
        </>
      )}
      {label}
    </Button>
  );
}
