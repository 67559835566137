import React, { useRef, useState, ReactNode } from "react";
import { InfoIcon } from "@primer/octicons-react";
import { Popover, PopoverBody } from "reactstrap";

interface Props {
  id: string;
  text?: ReactNode;
}

export default function HelpPopover(props: Props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const spanRef = useRef<HTMLSpanElement>(null);

  const toggle = () => setPopoverOpen(!popoverOpen);

  if (!props.text) {
    return null;
  }

  return (
    <>
      <span
        id={props.id}
        ref={spanRef}
        onClick={toggle}
        style={{ cursor: "pointer", padding: 4 }}
      >
        <InfoIcon />
      </span>
      {spanRef.current && (
        <Popover
          trigger="legacy"
          placement="bottom"
          isOpen={popoverOpen}
          toggle={toggle}
          target={props.id}
        >
          <PopoverBody>{props.text}</PopoverBody>
        </Popover>
      )}
    </>
  );
}

// const v = TextField<string>({ name: "", type: "text", label: "" });
